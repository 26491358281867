import React from 'react'
import styled from 'styled-components'

import { BaseMedContainer, MedHeaderContainer } from '../containers'
import { Checkbox, Text, Spacer } from '@truepill/react-capsule'

const ActiveMedCard = ({ setConfirmedMeds, medication, index }) => {
  const updateConfirmMed = () => {
    setConfirmedMeds(prevMeds => {
      const updateMeds = [...prevMeds]

      updateMeds[index].patient_confirmed = !updateMeds[index].patient_confirmed

      return updateMeds
    })
  }

  return (
    <BaseMedContainer onClick={updateConfirmMed}>
      <Checkbox onChange={updateConfirmMed} checked={medication.patient_confirmed} />
      <MedHeaderContainer>
        <Text bold>{medication.medication_name}</Text>
        <Text>{`Qty: ${medication.quantity} • Days Supply: ${medication.days_supply}`}</Text>
        <Text>{`Refills: ${medication.num_refills_remaining}`}</Text>
        <Spacer size="lg" />
        <Text bold>{`$${medication.copay_price.toFixed(2)}`}</Text>
      </MedHeaderContainer>
    </BaseMedContainer>
  )
}

export default ActiveMedCard
