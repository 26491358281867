import ReactGA from 'react-ga'
import memoize from 'lodash/memoize'
import { ClientHandleInput, ClientToggleCheckbox } from '../../graphql/mutations'

const handleInputChange = client => e => {
  const name = e.target.name
  const type = e.target.type
  // handle Checkbox events
  let value = type === 'checkbox' ? e.target.checked : e.target.value
  // handle integer values
  if (type === 'number') {
    value = parseInt(value, 10) || 0
  }
  client.mutate({
    mutation: ClientHandleInput,
    variables: {
      targetName: name,
      value,
    },
  })
}

const handleToggle = (client, checked) => e => {
  client.writeData({
    data: {
      [e.target.name]: !checked,
    },
  })
}

const handleToggleCheckbox = client => e => {
  const { checked, name } = e.target
  client.mutate({
    mutation: ClientToggleCheckbox,
    variables: {
      targetName: name,
      value: checked,
    },
  })
}

const scrollToTopOfPage = () => {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
  return null
}

const prettyDollars = inputNumber => {
  inputNumber = (Math.round(inputNumber * 100) / 100).toFixed(2)
  return '$' + inputNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const prettyNumber = inputNumber => {
  if (inputNumber) {
    return inputNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  return null
}

const initGA = memoize(() => {
  let options = {}
  if (process.env.NODE_ENV !== 'production') {
    options = {
      debug: true,
      testMode: true,
    }
  }
  ReactGA.initialize('UA-134643626-5', options)
  ReactGA.plugin.require('ec')
})

const initGAPageView = path => {
  initGA()
  ReactGA.pageview(path)
}

const formatPhoneNumber = phoneNumberString => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    const intlCode = match[1] ? '1 ' : ''
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return null
}

const clearSessionStorage = () => {
  sessionStorage.clear()
}

const clearLocalStorage = () => {
  localStorage.clear()
}

export {
  scrollToTopOfPage,
  handleInputChange,
  handleToggle,
  handleToggleCheckbox,
  prettyDollars,
  prettyNumber,
  initGAPageView,
  initGA,
  formatPhoneNumber,
  clearSessionStorage,
  clearLocalStorage,
}
