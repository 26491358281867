import { API_PATH } from '../../config'

export const sortMedications = medications => {
  const confirmed = []
  const future = []
  const out = []
  const dateNow = new Date()
  medications.forEach(med => {
    if (med.next_fill_date !== null && new Date(med.next_fill_date) < dateNow) {
      med.patient_confirmed = true
      confirmed.push(med)
    } else if (new Date(med.next_fill_date) > dateNow) {
      future.push(med)
    } else {
      out.push(med)
    }
  })
  return {
    confirmed,
    future,
    out,
  }
}

export const calculateTotal = (meds, shippingCost = 0) =>
  (meds.reduce((acc, med) => (med.patient_confirmed ? acc + med.copay_price : acc), 0) + shippingCost).toFixed(2)

export const createPaymentMethod = (stripeToken, url_token) => {
  return fetch(`${API_PATH}/payments/create/method`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      transaction_token: stripeToken,
      url_token,
    }),
  })
}

export const submitOrder = (body, url_token) => {
  return fetch(`${API_PATH}/orders/submit/${url_token}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body,
  })
}
