import React, { useState } from 'react'
import styled from 'styled-components'
import ReactGA from 'react-ga'

import { useLinkedCheckout } from '../../state/context'
import { NEXT_PAGE, UPDATE_CONFIRM_MEDS } from '../../state/reducer/actions'

import { MedicationList } from '../components/layout'

import { Header, Text, Button, ProgressBar, Spacer } from '@truepill/react-capsule'
import { CapsuleButtonContainer, ProgressBarContainer } from '../components/containers'
import { getStepProgress } from '../helpers'
import { SECTION_NAMES } from '../../constants'


const initializeFillableMeds = fillableMeds =>
  fillableMeds.map(med => ({
    ...med,
    patient_confirmed: true,
  }))

const calculateTotal = confirmedMeds => {
  if (confirmedMeds.length === 0) return 0

  return confirmedMeds
    .filter(med => med.patient_confirmed)
    .reduce((total, currentMed) => currentMed.copay_price + total, 0)
}

const DAY = 86400000

const ConfirmPrescriptions = () => {
  const { state, dispatch } = useLinkedCheckout()
  const {
    orderInfo: { sortedProducts, patientImageExists, orderHasControlledSubstance },
    patientIdentity: { year },
    currentPage,
  } = state
  const { fillable, future_fillable, out_of_refills } = sortedProducts

  const fillableMeds = fillable || []
  const refillMeds = future_fillable || []
  const outOfRefillsMeds = out_of_refills || []

  const [confirmedMeds, setConfirmedMeds] = useState(initializeFillableMeds(fillableMeds))
  const total = calculateTotal(confirmedMeds)
  const finalConfirmedMeds = confirmedMeds.filter(med => med.patient_confirmed)

  const handleBack = () =>
    dispatch({ 
      type: NEXT_PAGE, 
      payload: SECTION_NAMES.PATIENT_IDENTITY 
    })
  const handleSubmit = () => {

    ReactGA.plugin.execute('ec', 'setAction', 'checkout', {
      step: 1,
    })

    finalConfirmedMeds.forEach(med => {
      ReactGA.plugin.execute('ec', 'addProduct', {
        name: med.medication_name,
        price: med.copay_price,
        quantity: med.quantity,
      })
    })

    ReactGA.event({
      category: 'linkedCheckout',
      action: 'Confirmed Prescriptions',
      label: 'confirmedPrescriptionComplete',
    })

    dispatch({ type: UPDATE_CONFIRM_MEDS, payload: { total_copay: total, confirmedMeds: finalConfirmedMeds } })

    // If patient hasnt submitted a photo before and the order is controlled substance than show government ID upload
    if (!patientImageExists && orderHasControlledSubstance) {
      dispatch({ type: NEXT_PAGE, payload: SECTION_NAMES.PATIENT_UPLOAD })
    } else {
      dispatch({ type: NEXT_PAGE, payload: SECTION_NAMES.PATIENT_URAC })
    }
  }

  // Take the current date and add 4 days
  const estimatedDelivery = new Date(new Date().getTime() + DAY * 4).toLocaleDateString('en-US')
  return (
    <>
      <ProgressBarContainer>
        <ProgressBar value={getStepProgress(currentPage, patientImageExists)} />
      </ProgressBarContainer>
      <ConfirmPrescriptionContainer>
        <Header variant="4xl">Choose which medications you want filled.</Header>
        <Spacer size="md" axis="vertical" />
        <Text>
          The following medication(s) are ready to be filled. If there are any medications you don’t wish to fill,
          remove it before you continue.
        </Text>
        <Spacer size="xl" axis="vertical" />

        <Text style={{ fontSize: '1.75rem' }} bold>{`Total: $${total.toFixed(2)}`}</Text>

        {fillableMeds.length > 0 && (
          <MedicationList interactive setConfirmedMeds={setConfirmedMeds} medList={confirmedMeds} />
        )}
        {refillMeds.length > 0 && (
          <MedicationList
            medStatusHeader="Future Prescriptions:"
            medStatusText="The following medication(s) are not currently available to refill. We will notify you when they are."
            medList={refillMeds}
          />
        )}
        {outOfRefillsMeds.length > 0 && (
          <MedicationList
            medStatusHeader="Out of refills:"
            medStatusText="The following medication(s) are out of refills. We have contacted your prescriber to get an updated prescription."
            medList={outOfRefillsMeds}
          />
        )}

        <Spacer size="xl" axis="vertical" />
        <CapsuleButtonContainer>
          <Button variant="primary-text" onClick={handleBack}>
            Back
          </Button>
          <Button disabled={finalConfirmedMeds.length === 0} onClick={handleSubmit}>Continue</Button>
        </CapsuleButtonContainer>
        <Spacer size="md" axis="vertical" />
      </ConfirmPrescriptionContainer>
    </>
  )
}

const ConfirmPrescriptionContainer = styled.div``

export default ConfirmPrescriptions
